import React from "react";
import { useState } from "react";
import DailyreportbodyPost from "../components/DailyreportbodyPost";
import axios from "axios";
import swal from "sweetalert";
import config from "../config";

const DailyReportUpdateView = () => {

//     const [status, setStatus] = useState(undefined); // For message

// ///================ADD REPORT =========================
//    const addRepot=(reportData)=>{
//     axios
//     .post(`${config.apiUrl}input/`,reportData)
//     .then((res) => {
//       setStatus({ type: "success" });
//       swal("Success!", "Report successfully added", "success");
//     })
//     .catch((error) => {
//       setStatus({ type: "error", error });
//       swal("Error", "Report Not added", "warning");
//       console.log('Error', error.message);
//       console.log('Error', error.response.data);
//       console.log('Error', error.response.status);
      
//     });
//    }

  return (
    <div>
     fghg
    </div>
  );
};

export default DailyReportUpdateView;

