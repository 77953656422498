import React from "react";
import styles from "../assets/css/Footer.module.css";
import { Link, Navigate } from "react-router-dom";
const Footer = () => {
  return (
    <div className={styles.gradientfooterbg}>
      <div className={styles.footertext}>
      <Link to="/privacy">Privacy Policy</Link> <br/>
        Copyright &copy; Report Web 2023 | All right reseaved.
      </div>
    </div>
  );
};

export default Footer;
